/* ****************
 * GENERATED CODE *
 **************** */

import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSyncType } from "../../system/HewSyncType";
import { HewSync, type Writeable } from "../../system/HewSync";
import { UserEntityID, UserOrganizationID } from "../../system/IDs";


@HewSync.Type({
  "name": "UserEntity",
  "scope": "User",
  "table": "user",
  "type": "User:Entity"
})
export class UserEntity extends HewSyncType {
	public static readonly Type = UserEntity as typeof HewSyncType;
	public static override readonly type = "UserEntity";
	public override readonly type = UserEntity.type;

	@HewSync.Field({ })
	public readonly entity: UserEntityID;

	@HewSync.Field({ })
	public readonly kind: string;

	@HewSync.Field({ })
	public readonly organization: UserOrganizationID;

	@HewSync.Field({ })
	public readonly name: string;

	@HewSync.Field({ })
	public readonly lastUsedAt: HewSync.Timestamp;

	@HewSync.Field({ })
	public readonly createdAt: HewSync.Timestamp;

	@HewSync.Field({ })
	public readonly updatedAt: HewSync.Timestamp;


	/********************************************/

	public static get(ids: UserEntity.IDs) {
		this.initSubscriptions();

		let key = this.getKey(ids);
		let item = this.cache.get(key);

		if (item === undefined) {
			item = new UserEntity({
				...UserEntity.defaults(),
				entity: ids.entity,
				kind: ids.kind,
				organization: ids.organization,
			});
			this.cache.set(key, item);
			item.fetch(item.ids);
		}

		return item;
	}

	public static rawGet(ids: UserEntity.IDs) {
		return HewSync.get<UserEntity>(UserEntity.Type, ids);
	}

	public static list(/*...*/) {
		// TODO
	}

	public static listBy(params?: Partial<UserEntity.IDs>): HewSyncList<UserEntity> {
		let list = new (HewSyncList as any)(UserEntity.Type, undefined, params);
		return list;
	}

	public static rawList(inputs: Partial<UserEntity.IDs> & { limit?: number; nextToken?: string; }) {
		return HewSync.list<UserEntity>(UserEntity.Type, inputs);
	}

	public static batchGet(ids: UserEntity.IDs[]) {
		return HewSync.batchGet<UserEntity>(UserEntity.Type, ids);
	}

	public static create(values: UserEntity.Create) {
		return HewSync.create<UserEntity>(UserEntity.Type, "create", values);
	}

	public static update(ids: UserEntity.IDs, values: Partial<Pick<UserEntity, UserEntity.Modifiable>>, target?: UserEntity) {
		return HewSync.request<UserEntity>(UserEntity.Type, "update", {...values, ...ids}, target);
	}

	public static remove(ids: UserEntity.IDs, target?: UserEntity) {
		return HewSync.request<UserEntity>(UserEntity.Type, "remove", ids, target);
	}

	/********************************************/

	public update(values: Partial<Pick<UserEntity, UserEntity.Modifiable>>) {
		return UserEntity.update(this.ids, values, this);
	}

	public remove() {
		return UserEntity.remove(this.ids, this);
	}

	/********************************************/

	private static cache = new Map<string, UserEntity>();

	public get ids() { return { organization: this.organization, kind: this.kind, entity: this.entity }; }

	public static getKey(ids: UserEntity.IDs) { return ids.organization.toString() + "/" + ids.kind.toString() + "/" + ids.entity.toString(); }

	public get key() { return this.organization.toString() + "/" + this.kind.toString() + "/" + this.entity.toString(); }

	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).entity = data.entity;
		(this as any).kind = data.kind;
		(this as any).organization = data.organization;

		this.apply({ ...UserEntity.defaults(), ...data });
	}

	protected override apply(data: Partial<UserEntity.Fields>) {
		(this as any).name = data.name;
		(this as any).lastUsedAt = data.lastUsedAt;
		(this as any).createdAt = data.createdAt;
		(this as any).updatedAt = data.updatedAt;
		this.onUpdate.execute(this);
	}

	protected static defaults() {
		let values = {} as UserEntity;
		(values as any).name = "";
		(values as any).lastUsedAt = undefined;
		(values as any).createdAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		(values as any).updatedAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		return values;
	}

	protected static parse(data: any): Partial<UserEntity.IDs & UserEntity.Fields> {
		let output: Partial<Writeable<UserEntity.IDs & UserEntity.Fields>> = {};
		if (data.entity !== undefined && data.entity !== null) {
			output.entity = new UserEntityID(data.entity);
		}
		if (data.kind !== undefined && data.kind !== null) {
			output.kind = data.kind;
		}
		if (data.organization !== undefined && data.organization !== null) {
			output.organization = new UserOrganizationID(data.organization);
		}
		if (data.name !== undefined && data.name !== null) {
			output.name = data.name;
		}
		if (data.lastUsedAt !== undefined && data.lastUsedAt !== null) {
			output.lastUsedAt = new HewSync.Timestamp(data.lastUsedAt);
		}
		if (data.createdAt !== undefined && data.createdAt !== null) {
			output.createdAt = new HewSync.Timestamp(data.createdAt);
		}
		if (data.updatedAt !== undefined && data.updatedAt !== null) {
			output.updatedAt = new HewSync.Timestamp(data.updatedAt);
		}
		return output;
	}

	protected toJSON(): any {
		return {
			entity: this.entity,
			kind: this.kind,
			organization: this.organization,
			name: this.name,
			lastUsedAt: this.lastUsedAt,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
		};
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this.Type, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}

	public static readonly fieldIDs = ["organization", "kind", "entity"] as const;
	public readonly fieldIDs = UserEntity.fieldIDs;
	public static readonly fields = ["name", "lastUsedAt", "createdAt", "updatedAt"] as const;
	public readonly fields = UserEntity.fields;

	public get kind_entity() {
		return this.kind + "/" + this.entity.value;
	}
}

export namespace UserEntity {
	export type IDs = Pick<UserEntity, UserEntity.IDNames>;
	export type IDNames = "organization" | "kind" | "entity";
	export type Fields = Pick<UserEntity, UserEntity.FieldNames>;
	export type FieldNames = "name" | "lastUsedAt" | "createdAt" | "updatedAt";
	export type Modifiable = "name" | "lastUsedAt";
	export type Create = {
		kind: string;
		organization: UserOrganizationID;
		name: string;
		lastUsedAt?: HewSync.Timestamp;
	}

}
